import { StaticPageConfig } from '@7egend/dxp-web-core/lib/modules/cms/StaticPages/types';
import { CUSTOM_BLOCKS } from '../blocks';

const {
  album,
  image,
  text,
  title,
} = CUSTOM_BLOCKS.types;

export default {
  sections: {
    "5": {
      image: "",
      containers: {
        "events/header": {
          blocks: [
            {
              types: [`${image.type}_${image.style}`]
            },
            {
              types: [`${title.type}_${title.style}`]
            }
          ]
        },
        "events/anniversary": {
          blocks: [
            {
              types: [`${title.type}_${title.style}`]
            },
            {
              types: [`${text.type}_${text.style}`]
            },
            {
              types: [`${text.type}_${text.style}`]
            }
          ]
        },
        "events/gallery": {
          blocks: [
            {
              types: [album.type]
            }
          ]
        },
        "events/events": {
          blocks: [
            {
              types: [`${title.type}_${title.style}`]

            },
            {
              types: [`${text.type}_${text.style}`]
            }
          ]
        }
      }
    }
  }
} as StaticPageConfig

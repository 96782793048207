import { StaticPageConfig } from '@7egend/dxp-web-core/lib/modules/cms/StaticPages/types';

export default {
  sections: {
    "3": {
      containers: {
        "footlab-detail/news": {
          blocks: [
            {
              types: ["14_1"]
            }
          ]
        }
      }
    }
  }
} as StaticPageConfig

/* eslint-disable no-template-curly-in-string */
import { NewsCreateInput, NewsGetByIdInput, NewsUpdateInput } from '@7egend/dxp-web-core/lib/dlos/cms/news';
import { DloExtension as CoreDloExtension, DloInput, mergeQLQueries } from '@7egend/web-core/lib/extensions/dlo';
import { FeedGetAllDatatableInput, FeedUpdateOrderInput, FeedItemDeleteInput } from '@7egend/dxp-web-apps/lib/dlos/feed';

export class DloExtension extends CoreDloExtension {
  public call(input: DloInput) {
    /** Feed */
    if (input.constructor === FeedGetAllDatatableInput) {
      (input as DloInput).uri = "feed/datatables?draw=${timeStamp}";
    }
    if (input.constructor === FeedUpdateOrderInput) {
      (input as DloInput).uri = "feed/${id}/relevance";
    }
    if (input.constructor === FeedItemDeleteInput) {
      (input as DloInput).uri = "feed/${id}";
    }
    /** News */
    if (input.constructor === NewsGetByIdInput) {
      (input as any).fields = mergeQLQueries(
        (input as any).fields,
        [
          "place(uuid)"
        ]
      )
    }

    if (input.constructor === NewsUpdateInput) {
      (input as any).fields = mergeQLQueries(
        (input as any).fields,
        [
          "place_id"
        ]
      )
    }

    if (input.constructor === NewsCreateInput) {
      (input as any).fields = mergeQLQueries(
        (input as any).fields,
        [
          "place_id"
        ]
      )
    }

    return super.call(input);
  }
}

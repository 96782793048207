import { StaticPageConfig } from '@7egend/dxp-web-core/lib/modules/cms/StaticPages/types';
import feedConfig from "./feedConfig"
import home from "./staticPages/home"
import footlab from "./staticPages/footlab"
import activitiesGroup from './staticPages/activitiesGroup';
import activitiesIndividual from './staticPages/activitiesIndividual';
import events from "./staticPages/events"
import contacts from "./staticPages/contacts"
import footlabDetail from "./staticPages/footlabDetail"
import academy from './staticPages/academy';
import rankings from './staticPages/rankings';
import faqs from './staticPages/faqs';
import academyPrices from './staticPages/academyPrices';
import activitiesPrices from './staticPages/activitiesPrices';
import birthdayPrices from './staticPages/birthdayPrices';
import activitiesPersonalTraining from './staticPages/activitiesPersonalTraining';
import booking from './staticPages/booking';
import tribuneSportsBarDefault from './staticPages/tribuneSportsBarDefault';
import tribunePrivateRoomsDefault from './staticPages/tribunePrivateRoomsDefault';
import tribuneSportsBarAlmaty from './staticPages/tribuneSportsBarAlmaty';
import tribunePrivateRoomsAlmaty from './staticPages/tribunePrivateRoomsAlmaty';

export const STATIC_PAGES_CONFIG: StaticPageConfig = {
  sections: {
    ...home.sections,
    ...footlab.sections,
    ...footlabDetail.sections,
    ...activitiesGroup.sections,
    ...activitiesIndividual.sections,
    ...events.sections,
    ...contacts.sections,
    ...academy.sections,
    ...rankings.sections,
    ...faqs.sections,
    ...academyPrices.sections,
    ...activitiesPrices.sections,
    ...birthdayPrices.sections,
    ...activitiesPersonalTraining.sections,
    ...booking.sections,
    ...tribuneSportsBarDefault.sections,
    ...tribunePrivateRoomsDefault.sections,
    ...tribuneSportsBarAlmaty.sections,
    ...tribunePrivateRoomsAlmaty.sections,
  }
}

export { feedConfig }

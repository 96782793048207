import { StaticPageConfig } from '@7egend/dxp-web-core/lib/modules/cms/StaticPages/types';
import { CUSTOM_BLOCKS } from '../blocks';

const {
  image,
  smallText,
  title,
  expansionPanel,
} = CUSTOM_BLOCKS.types;

export default {
    sections: {
        "13": {
            containers: {
                "faqs/header": {
                    blocks: [
                        {
                            types: [`${image.type}_${image.style}`]
                        },
                        {
                            types: [`${title.type}_${title.style}`],
                        },
                        {
                            types: [`${smallText.type}_${smallText.style}`],
                        }
                    ]
                },
                "faqs/content": {
                    blocks: [
                        {
                            types: [`${expansionPanel.type}_${expansionPanel.style}`],
                        }
                    ]
                },
            },
        },
    }
} as StaticPageConfig
import { StaticPageConfig } from '@7egend/dxp-web-core/lib/modules/cms/StaticPages/types';
import { CUSTOM_BLOCKS } from '../blocks';

const {
  container,
  image,
  text,
  title,
  cta,
  album,
} = CUSTOM_BLOCKS.types;

export default {
  sections: {
    "19": {
      containers: {
        "tribune-sports-bar-6/header": {
          blocks: [
            {
              types: [container.type],
              container: {
                blocks: [
                  {
                    types: [`${image.type}_${image.style}`],
                  },
                ]
              }
            },
            {
              types: [container.type],
              container: {
                blocks: [
                  {
                    types: [`${image.type}_${image.style}`],
                  },
                ]
              }
            },
            {
              types: [container.type],
              container: {
                blocks: [
                  {
                    types: [`${image.type}_${image.style}`],
                  },
                ]
              }
            },
            {
              types: [container.type],
              container: {
                blocks: [
                  {
                    types: [`${image.type}_${image.style}`],
                  },
                ]
              }
            }
          ]
        },
        "tribune-sports-bar-6/scroll-bar": {
          blocks: [
            {
              types: [`${cta.type}_${cta.style}`],
            },
            {
              types: [`${text.type}_${text.style}`]
            },
          ]
        },
        "tribune-sports-bar-6/intro": {
          blocks: [
            {
              types: [`${title.type}_${text.style}`]
            },
            {
              types: [`${text.type}_${text.style}`]
            },
            {
              types: [`${text.type}_${text.style}`]
            }
          ],
        },
        "tribune-sports-bar-6/menu": {
          blocks: [
            {
              types: [container.type],
              container: {
                blocks: [
                  {
                    types: [`${title.type}_${title.style}`]
                  },
                  {
                    types: [`${text.type}_${text.style}`]
                  },
                  {
                    types: [`${cta.type}_${cta.style}`],
                  },
                  {
                    types: [album.type],
                  },
                ]
              }
            },
            {
              types: [container.type],
              container: {
                blocks: [
                  {
                    types: [`${title.type}_${title.style}`]
                  },
                  {
                    types: [`${text.type}_${text.style}`]
                  },
                  {
                    types: [`${cta.type}_${cta.style}`],
                  },
                  {
                    types: [album.type],
                  },
                ]
              }
            },
            {
              types: [container.type],
              container: {
                blocks: [
                  {
                    types: [`${title.type}_${title.style}`]
                  },
                  {
                    types: [`${text.type}_${text.style}`]
                  },
                  {
                    types: [`${cta.type}_${cta.style}`],
                  },
                  {
                    types: [album.type],
                  },
                ]
              }
            },
            {
              types: [container.type],
              container: {
                blocks: [
                  {
                    types: [`${title.type}_${title.style}`]
                  },
                  {
                    types: [`${text.type}_${text.style}`]
                  },
                  {
                    types: [`${cta.type}_${cta.style}`],
                  },
                  {
                    types: [album.type],
                  },
                ]
              }
            },
            {
              types: [container.type],
              container: {
                blocks: [
                  {
                    types: [`${title.type}_${title.style}`]
                  },
                  {
                    types: [`${text.type}_${text.style}`]
                  },
                  {
                    types: [`${cta.type}_${cta.style}`],
                  },
                  {
                    types: [album.type],
                  },
                ]
              }
            },
          ]
        },
        "tribune-sports-bar-6/location-info": {
          blocks: [
            {
              types: [`${text.type}_${text.style}`]
            },
            {
              types: [`${text.type}_${text.style}`]
            },
            {
              types: [`${text.type}_${text.style}`]
            },
          ]
        },
        "tribune-sports-bar-6/gallery": {
          blocks: [
            {
              types: [album.type],
            },
          ]
        }
      }
    }
  }
} as StaticPageConfig

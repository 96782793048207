import React from "react";
import { WithI18nProps, withI18n } from "@7egend/web-core/lib/components/withI18n";
import {
    BlockComponentProps,
    Wrapper,
} from "@7egend/dxp-web-core/lib/components";
import { blocksI18n } from "./i18n";
import { Trans } from "@7egend/web-core/lib/components/i18n";

const Structure = {
    type: "14",
    language: "1",
    content: {
        code: "1",
    }
} as any;

const ImageComponent: React.FC<BlockComponentProps & WithI18nProps> = ({ data, updateData, t }) => {
    return (
        <Wrapper>
            <Trans id="newsWidget.message" />
        </Wrapper>
    );
}

const Component = withI18n(blocksI18n)(ImageComponent);

const Button = {
    icon: "widgets",
    name: "News Widget",
};

export const NewsWidget = {
    key: "14_1",
    Structure,
    Component,
    Button,
};

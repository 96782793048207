import { StaticPageConfig } from '@7egend/dxp-web-core/lib/modules/cms/StaticPages/types'
import { CUSTOM_BLOCKS } from '../blocks'

const { image, title, video, text, album } =
  CUSTOM_BLOCKS.types

export default {
  sections: {
    '21': {
      containers: {
        'footlab-academy/header': {
          blocks: [
            {
              types: [`${video.type}_${video.style}`],
            },
            {
              types: [`${title.type}_${title.style}`],
            },
          ],
        },
        'footlab-academy/intro': {
          blocks: [
            {
              types: [`${image.type}_${image.style}`],
            },
            {
              types: [`${title.type}_${title.style}`],
            },
            {
              types: [`${text.type}_${text.style}`],
            },
          ],
        },
        'footlab-academy/gallery': {
          blocks: [
            {
              types: [`${album.type}`],
            },
          ],
        },
        'footlab-academy/about': {
          blocks: [
            {
              types: [`${title.type}_${title.style}`],
            },
            {
              types: [`${text.type}_${text.style}`],
            },
            {
              types: [`${text.type}_${text.style}`],
            },
          ],
        },
        'footlab-academy/space': {
          blocks: [
            {
              types: [`${title.type}_${title.style}`],
            },
            {
              types: [`${text.type}_${text.style}`],
            },
            {
              types: [`${album.type}`],
            },
            {
              types: [`${image.type}_${image.style}`],
            },
            {
              types: [`${title.type}_${title.style}`],
            },
          ],
        },
        'footlab-academy/philosophy': {
          blocks: [
            {
              types: [`${title.type}_${title.style}`],
            },
            {
              types: [`${text.type}_${text.style}`],
            },
          ],
        },
        'footlab-academy/testimonial': {
            blocks: [
              {
                types: [`${title.type}_${title.style}`],
              },
              {
                types: [`${text.type}_${text.style}`],
              },
              {
                types: [`${image.type}_${image.style}`],
              },
            ],
          },
      },
    },
  },
} as StaticPageConfig

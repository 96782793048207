import { StaticPageConfig } from '@7egend/dxp-web-core/lib/modules/cms/StaticPages/types';
import { CUSTOM_BLOCKS } from '../blocks';

const {
  album,
  cta,
  image,
  text,
  title,
  video,
} = CUSTOM_BLOCKS.types;

export default {
  sections: {
    "2": {
      image: "",
      containers: {
        "footlab/header": {
          blocks: [
            {
              types: [`${image.type}_${image.style}`]
            },
            {
              types: [`${title.type}_${title.style}`]
            }
          ]
        },
        "footlab/about": {
          blocks: [
            {
              types: [`${title.type}_${title.style}`]
            },
            {
              types: [`${text.type}_${text.style}`]
            },
            {
              types: [`${text.type}_${text.style}`]
            }
          ]
        },
        "footlab/champion": {
          blocks: [
            {
              types: [`${title.type}_${title.style}`]
            },
            {
              types: [`${text.type}_${text.style}`]
            },
            {
              types: [album.type]
            },
            {
              types: [`${image.type}_${image.style}`]
            },
            {
              types: [`${title.type}_${title.style}`]
            }
          ]
        },
        "footlab/video": {
          blocks: [
            {
              types: [`${video.type}_${video.style}`]
            }
          ]
        },
        "footlab/stadium": {
          blocks: [
            {
              types: [`${title.type}_${title.style}`]
            },
            {
              types: [`${text.type}_${text.style}`]
            },
            {
              types: [`${cta.type}_${cta.style}`]
            }
          ]
        },
        "footlab/gallery": {
          blocks: [
            {
              types: [album.type]
            }
          ]
        },
        "footlab/fields": {
          blocks: [
            {
              types: [`${title.type}_${title.style}`]
            },
            {
              types: [`${text.type}_${text.style}`]
            },
            {
              types: [`${text.type}_${text.style}`]
            },
            {
              types: [`${cta.type}_${cta.style}`],
              required: false
            }
          ]
        },
        "footlab/fields_gallery": {
          blocks: [
            {
              types: [`${title.type}_${title.style}`]
            },
            {
              types: [`${text.type}_${text.style}`]
            },
            {
              types: [album.type]
            }
          ]
        },
        "footlab/news": {
          blocks: [
            {
              types: ["14_1"]
            }
          ]
        }
      }
    }
  }
} as StaticPageConfig

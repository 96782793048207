import React from 'react';
import { APP_TRANSLATIONS } from "./i18n";
import { withI18n, WithI18nProps } from "@7egend/web-core/lib/components/withI18n";
import { NewsCreateInput, NewsUpdateInput } from '@7egend/dxp-web-core/lib/dlos/cms/news';
import { DloInput } from '@7egend/web-core/lib/extensions/dlo';
import { PageEditorRenderOptions } from '@7egend/dxp-web-core/lib/components/Structure/PageEditor/PageEditor';
import { Select, SelectOption } from '@7egend/dxp-web-core/lib/components/Select';
import { LocationsGetByUserIdInput } from '../../dlos/news';
import { WithSessionProps, withSession } from "@7egend/web-core-security/lib/components/withSession/withSession";
import { CorePureComponent } from '@7egend/web-core';
import { LocationsFootlabGetAllInput } from '../../dlos/locations';
import { Divider, Space } from "@7egend/dxp-web-core";
import { PostsEditor } from '@7egend/dxp-web-core/lib/modules/cms/Posts/PostsEditor';

interface OverridedNews extends NewsCreateInput {
    place: {
        uuid: string
    }
}

interface OverridedNewsInput extends NewsCreateInput {
    place_id: string
}

interface NewsEditorOverrideComponentState {
    places?: SelectOption[];
    place?: string;
    isPlaceRequired?: boolean;
}

class NewsEditorOverrideComponent extends CorePureComponent<WithI18nProps & WithSessionProps, NewsEditorOverrideComponentState> {

    public state: NewsEditorOverrideComponentState = {
        isPlaceRequired: true
    }

    public componentDidMount() {
        this.fetchUserAvailableLocations()
            .then(res => {
                if (res.data && res.data.length > 0) {
                    const places: SelectOption[] = [];
                    res.data.forEach((eachPlace: { uuid: string; name: string; }) => {
                        places.push(
                            {
                                value: eachPlace.uuid,
                                label: eachPlace.name,
                                option: {
                                    value: eachPlace.uuid
                                }
                            }
                        );
                    });
                    this.setState({
                        places,
                        place: "",
                        isPlaceRequired: true
                    })
                } else {
                    this.fetchAllLocations()
                        .then(res => {
                            if (res.data && res.data.length > 0) {
                                const places: SelectOption[] = [];
                                res.data.forEach((eachPlace: { uuid: string; name: string; }) => {
                                    places.push(
                                        {
                                            value: eachPlace.uuid,
                                            label: eachPlace.name,
                                            option: {
                                                value: eachPlace.uuid
                                            }
                                        }
                                    );
                                });
                                this.setState({
                                    places,
                                    place: "",
                                    isPlaceRequired: false
                                })
                            } else {
                                this.fetchAllLocations()
                            }
                        })
                        .catch(err => this.fw.log.info('fetch all locations', err))
                }
            })
            .catch(err => this.fw.log.info('fetch locations by user uuid', err))
    }

    render() {
        const { t } = this.props;

        const flex: any = {
            fields: [
                {
                    component: ({ data, setData }: PageEditorRenderOptions<OverridedNews>) => {
                        if (!this.state?.places) return null;

                        return (
                            <>
                                <Divider space={Space.Small} />
                                <Select
                                    name="target"
                                    label={t("news.editor.fieldLocalizationLabel")}
                                    options={this.state?.places || []}
                                    defaultLabel={(!this.state.isPlaceRequired || this.state.places.length > 1) ? t("news.editor.fieldLocalizationName") : undefined}
                                    defaultValue={(!this.state.isPlaceRequired || this.state.places.length > 1) ? "-1" : undefined}
                                    value={data?.place?.uuid || ""}
                                    onChange={(place: string) => setData({
                                        ...data,
                                        place: {
                                            uuid: place
                                        }
                                    })}
                                />
                            </>
                        )
                    }
                },
            ],
            beforeSubmit: [
                (request: DloInput<NewsCreateInput | NewsUpdateInput>, options: any) => {
                    const { isPlaceRequired } = this.state;
                    const isPlaceEmpty = !(options.data?.place?.uuid || this?.state?.place);
                    const isPlaceUnvailable = options.data?.place?.uuid === "-1";

                    if (isPlaceRequired && isPlaceEmpty) {
                        (request.body as OverridedNewsInput).place_id = (options.data?.place?.uuid) || (this?.state?.places && this?.state?.places[0].value) || undefined;
                        return request;
                    }

                    if (isPlaceRequired && isPlaceUnvailable) {
                        options.addMessage(t("news.editor.fieldLocalizationRequired"), "error", 4000)
                        throw t("news.editor.fieldLocalizationRequired");
                    }

                    (request.body as OverridedNewsInput).place_id = options.data?.place?.uuid || this?.state?.place || undefined;

                    return request;
                }
            ]
        };

        return (
            <PostsEditor
                flex={flex}
            />
        );
    }

    private fetchUserAvailableLocations = async () => {
        const locationsDLO = new LocationsGetByUserIdInput();

        locationsDLO.body = {
            uuid: this.props.session.user?.uuid || "",
        };

        /** Request Categories */
        const requestCategories = this.fw.dlo.call(locationsDLO);

        return Promise.all([requestCategories]).then(res => {
            return {
                data: res[0].body
            }
        });
    }

    private fetchAllLocations = async () => {
        const locationsDLO = new LocationsFootlabGetAllInput();

        /** Request Categories */
        const requestCategories = this.fw.dlo.call(locationsDLO);

        return Promise.all([requestCategories]).then(res => {
            return {
                data: res[0].body
            }
        });
    }
}

export const NewsEditorOverride = withSession(withI18n(APP_TRANSLATIONS)(NewsEditorOverrideComponent)) as React.ComponentClass;

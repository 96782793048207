import React from 'react';
import { Icon } from "@7egend/dxp-web-core";
import Styled from 'styled-components';
import { FeedConfig } from '@7egend/dxp-web-apps/lib/screens/Feed'

const StyledIcon = Styled(Icon)`
    font-size: 25px;
`;

export default {
    types: {
        "1": <StyledIcon icon="receipt_long" />,
        "10": <StyledIcon icon="whatshot" />,
    }
} as FeedConfig["feed"];

import { RolesPermissions } from "@7egend/dxp-web-core/lib/config";

enum RoleType {
    Admin = 1,
    Footlab = 4,
    News = 5
}

export const roles: RolesPermissions = {
    [RoleType.News]: {
        permissions: [
            "cms",
            "cms.news.list",
            "cms.news.add",
            "cms.news.edit",
            "cms.multimedia.list",
            "cms.multimedia.edit",
            "cms.albums.list",
            "cms.albums.add",
            "cms.albums.edit",
        ]
    },
    [RoleType.Footlab]: {
        permissions: [
            "cms",
            "cms.news.list",
            "cms.news.add",
            "cms.news.edit",
            "cms.multimedia.list",
            "cms.multimedia.edit",
            "cms.albums.list",
            "cms.albums.add",
            "cms.albums.edit",
            "footlab",
            "footlab.locations.list",
            "footlab.locations.edit",
        ]
    },
    [RoleType.Admin]: {
        permissions: [
            "apps",
            "apps.feed.list",
            "footlab",
            "footlab.locations.list",
            "footlab.locations.edit",
            "footlab.services.list",
            "footlab.services.edit",
            "cms",
            "cms.news.list",
            "cms.news.add",
            "cms.news.edit",
            "cms.albums.list",
            "cms.albums.add",
            "cms.albums.edit",
            "cms.multimedia.list",
            "cms.multimedia.edit",
            "cms.boosters.list",
            "cms.boosters.add",
            "cms.boosters.edit",
            "cms.dynamic-pages.list",
            "cms.dynamic-pages.add",
            "cms.dynamic-pages.edit",
            "cms.static-pages.list",
            "cms.static-pages.edit",
            "cms.menu.list",
            "cms.menu.add",
            "cms.menu.edit",
            "cms.forms.list",
            "cms.forms.details",
            "cms.forms.download",
            "people",
            "people.users",
            "people.users.add",
            "people.users.edit",
        ]
    },
};

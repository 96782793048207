import { StaticPageConfig } from '@7egend/dxp-web-core/lib/modules/cms/StaticPages/types';
import { CUSTOM_BLOCKS } from '../blocks';

const {
  container,
  image,
  text,
  title,
} = CUSTOM_BLOCKS.types;

export default {
  sections: {
    "18": {
      containers: {
        "tribune-private-rooms/header": {
          blocks: [
            {
              types: [container.type],
              container: {
                blocks: [
                  {
                    types: [`${image.type}_${image.style}`],
                  },
                ]
              }
            },
            {
              types: [container.type],
              container: {
                blocks: [
                  {
                    types: [`${image.type}_${image.style}`],
                  },
                ]
              }
            },
            {
              types: [container.type],
              container: {
                blocks: [
                  {
                    types: [`${image.type}_${image.style}`],
                  },
                ]
              }
            },
            {
              types: [container.type],
              container: {
                blocks: [
                  {
                    types: [`${image.type}_${image.style}`],
                  },
                ]
              }
            }
          ]
        },
        "tribune-private-rooms/service-unavailable": {
          blocks: [
            {
              types: [`${title.type}_${text.style}`]
            },
            {
              types: [`${text.type}_${text.style}`]
            },
            {
              types: [`${text.type}_${text.style}`]
            }
          ],
        }
      }
    }
  }
} as StaticPageConfig

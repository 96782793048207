import { Block } from "@7egend/web-core-cms/lib/dlos/block"
import { Medium } from "@7egend/web-core-media/lib/dlos";
import { BlockType } from '@7egend/dxp-web-core/lib/components';
import { Text as TextDLO } from "@7egend/web-core-cms/lib/dlos/text";
import { NewsWidget } from '../blocks/NewsWidget';
import { CTA } from "@7egend/web-core-cms/lib/dlos/cta";
import { BlockInfo } from "@7egend/dxp-web-core/lib/components/BlocksEditor/BlocksEditor";

/**
 * Block keys override
 */
const blockTypes = {
    album: {
        type: "100",
    },
    container: {
        type: "5",
    },
    cta: {
        type: "6",
        style: "1"
    },
    medium: {
        type: "101",
    },
    image: {
        type: "101",
        style: "1",
    },
    text: {
        type: "2",
        style: "1",
    },
    highlight: {
        type: "2",
        style: "2",
    },
    title: {
        type: "2",
        style: "3",
    },
    smallText: {
        type: "2",
        style: "5",
    },
    video: {
        type: "101",
        style: "2",
    },
    widget: {
        type: "14",
        style: "1",
    },
    expansionPanel: {
        type: "5",
        style: "4",
    },
};

/**
 * Block structure override
 */
const titleOverride = {
    ...BlockType.Title,
    key: `${blockTypes.title.type}_${blockTypes.title.style}`,
    Structure: {
        ...BlockType.Title.Structure,
        type: blockTypes.title.type,
    } as Block<TextDLO>
};
const textOverride = {
    ...BlockType.Text,
    key: `${blockTypes.text.type}_${blockTypes.text.style}`,
    Structure: {
        ...BlockType.Text.Structure,
        type: blockTypes.text.type,
    } as Block<TextDLO>
};
const smallTextOverride = {
    ...BlockType.SmallText,
    key: `${blockTypes.smallText.type}_${blockTypes.smallText.style}`,
    Structure: {
        ...BlockType.SmallText.Structure,
        type: blockTypes.smallText.type,
    } as Block<TextDLO>
};
const highlightOverride = {
    ...BlockType.Highlight,
    key: `${blockTypes.highlight.type}_${blockTypes.highlight.style}`,
    Structure: {
        ...BlockType.Highlight.Structure,
        type: blockTypes.highlight.type,
    } as Block<TextDLO>
};
const albumOverride = {
    ...BlockType.Album,
    key: blockTypes.album.type,
    Structure: {
        type: blockTypes.album.type
    } as Block<Medium>
};
const imageOverride = {
    ...BlockType.Image,
    key: `${blockTypes.image.type}_${blockTypes.image.style}`,
    Structure: {
        ...BlockType.Image.Structure,
        type: blockTypes.image.type,
    } as Block<Medium>
};
const videoOverride = {
    ...BlockType.Video,
    key: `${blockTypes.video.type}_${blockTypes.video.style}`,
    Structure: {
        ...BlockType.Video.Structure,
        type: blockTypes.video.type,
    } as Block<Medium>
};
const ctaOverride = {
    ...BlockType.CTA,
    key: `${blockTypes.cta.type}_${blockTypes.cta.style}`,
    Structure: {
        ...BlockType.CTA.Structure,
        type: blockTypes.cta.type,
    } as unknown as Block<CTA>
};

const expansionPanelOverride = {
    ...BlockType.ExpansionPanel,
    key: `${blockTypes.expansionPanel.type}_${blockTypes.expansionPanel.style}`,
    Structure: {
        ...BlockType.ExpansionPanel.Structure,
        type: blockTypes.expansionPanel.type,
        content: {
            ...BlockType.ExpansionPanel.Structure.content,
            blocks: [{
                type: blockTypes.text.type,
                content: {
                    style: "6",
                }
            }] as unknown as Block<Text>
        }
    } as unknown as Block<CTA>
};


/**
 * Block mapping
 */
const blocks = {
    news: [
        titleOverride,
        textOverride,
        albumOverride,
        imageOverride,
        videoOverride,
    ] as BlockInfo[],
    pages: [
        titleOverride,
        textOverride,
        albumOverride,
        imageOverride,
        videoOverride,
        ctaOverride,
    ] as BlockInfo[],
    staticPages: [
        titleOverride,
        textOverride,
        smallTextOverride,
        highlightOverride,
        albumOverride,
        imageOverride,
        videoOverride,
        ctaOverride,
        expansionPanelOverride,
        NewsWidget,
    ] as BlockInfo[]
};

/**
 * Custom resolver
 */
const resolver = (block: Block, defaultResolver: () => any) => {
    // Album
    if (block.type === blockTypes.album.type) {
        return block.type
    }

    if (block.type === blockTypes.cta.type) {
        return `${block.type}_${(block as Block<CTA>).content.style}`;
    }

    // Block text (simple or highlight)
    if (block.type === blockTypes.text.type && block.content) {
        return `${block.type}_${(block as Block<TextDLO>).content.style}`;
    }

    /** Block multimedia (image, panoramic, video or document) */
    if (block.type === blockTypes.medium.type && block.content) {
        if ((block as Block<Medium>).content.panoramic === "1") {
            return `${block.type}_${(block as Block<Medium>).content.medium_type}_1`;
        }
        return `${block.type}_${(block as Block<Medium>).content.medium_type}`;
    }

    /* Try resolve with default resolver */
    return defaultResolver();
}

export const CUSTOM_BLOCKS: any = {
    resolver,
    types: blockTypes,
    blocks
};

import { StaticPageConfig } from '@7egend/dxp-web-core/lib/modules/cms/StaticPages/types';
import { CUSTOM_BLOCKS } from '../blocks';

const {
  container,
  cta,
  image,
  text,
  title,
  video,
} = CUSTOM_BLOCKS.types;

export default {
  sections: {
    "1": {
      image: "",
      containers: {
        "homepage/highlights": {
          blocks: [
            {
              types: [container.type],
              container: {
                blocks: [
                  {
                    types: [`${image.type}_${image.style}`],
                  },
                  {
                    types: [`${title.type}_${title.style}`],
                  },
                  {
                    types: [`${cta.type}_${cta.style}`],
                  }
                ]
              }
            },
            {
              types: [container.type],
              container: {
                blocks: [
                  {
                    types: [`${image.type}_${image.style}`],
                  },
                  {
                    types: [`${title.type}_${title.style}`],
                  },
                  {
                    types: [`${cta.type}_${cta.style}`],
                  }
                ]
              }
            },
            {
              types: [container.type],
              container: {
                blocks: [
                  {
                    types: [`${image.type}_${image.style}`],
                  },
                  {
                    types: [`${title.type}_${title.style}`],
                  },
                  {
                    types: [`${cta.type}_${cta.style}`],
                  }
                ]
              }
            },
            {
              types: [container.type],
              container: {
                blocks: [
                  {
                    types: [`${image.type}_${image.style}`],
                  },
                  {
                    types: [`${title.type}_${title.style}`],
                  },
                  {
                    types: [`${cta.type}_${cta.style}`],
                  }
                ]
              }
            }
          ]
        },
        "homepage/what_is_footlab": {
          blocks: [
            {
              types: [`${title.type}_${title.style}`]
            },
            {
              types: [`${text.type}_${text.style}`]
            },
            {
              types: [`${text.type}_${text.style}`]
            },
            {
              types: [`${cta.type}_${cta.style}`]
            }
          ]
        },
        "homepage/video": {
          blocks: [
            {
              types: [`${video.type}_${video.style}`]
            }
          ]
        },
        "homepage/how_it_works": {
          blocks: [
            {
              types: [`${title.type}_${title.style}`]
            },
            {
              types: [`${text.type}_${text.style}`]
            },
            {
              types: [`${cta.type}_${cta.style}`]
            }
          ]
        },
        "homepage/organize_event": {
          blocks: [
            {
              types: [`${image.type}_${image.style}`]
            },
            {
              types: [`${title.type}_${title.style}`]
            },
            {
              types: [`${text.type}_${text.style}`]
            },
            {
              types: [`${cta.type}_${cta.style}`]
            }
          ]
        },
        "homepage/facilities": {
          blocks: [
            {
              types: [`${title.type}_${title.style}`]
            },
            {
              types: [`${text.type}_${text.style}`]
            },
            {
              types: [`${text.type}_${text.style}`]
            }
          ]
        },
        "homepage/news": {
          blocks: [
            {
              types: ["14_1"]
            }
          ]
        }
      }
    }
  }
} as StaticPageConfig

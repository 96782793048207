import { bootstrap, CMSConfig } from "@7egend/dxp-web-core"
import { Config } from '@7egend/dxp-web-core/lib/base/config';
import { FlagsConfig } from '@7egend/dxp-web-core/lib/base/flags';
import apps from "@7egend/dxp-web-apps";
import { FeedConfig } from '@7egend/dxp-web-apps/lib/screens/Feed';
import { feedConfig, STATIC_PAGES_CONFIG } from './configs';
import { roles } from './configs/roles';

/** Load client specific pages */
import { locationsSetup } from './screens/Locations';
import { servicesSetup } from './screens/Services';
import { CUSTOM_BLOCKS } from './configs/blocks';
import FootlabIcon from "./assets/footlab.svg";

/** Override components */
import { NewsEditorOverride } from './screens/News/NewsEditor';
import { DloExtension } from "./extensions/dlo";

bootstrap({
    modules: [
        //@ts-ignore
        apps,
        () => {
            return {
                key: "footlab",
                routes: [
                    ...locationsSetup.routes,
                    ...servicesSetup.routes,
                    {
                        path: "cms/posts/add",
                        component: NewsEditorOverride
                    },
                    {
                        path: "cms/posts/edit/:id",
                        component: NewsEditorOverride
                    }
                ],
                menus: [
                    {
                        title: {
                            key: "footlab.menu.title",
                            en: "Footlab",
                            pt: "Footlab",
                            es: "Footlab",
                        },
                        path: "footlab",
                        permission: "footlab",
                        children: [
                            ...locationsSetup.menus,
                            ...servicesSetup.menus,
                        ],
                        icon: FootlabIcon,
                    },
                ],
                blocks: {
                    "cms.pages": CUSTOM_BLOCKS.blocks.pages,
                    "cms.news": CUSTOM_BLOCKS.blocks.news,
                    "cms.static-pages": CUSTOM_BLOCKS.blocks.staticPages
                },
            }
        }
    ],
    config: {
        flags: {
            cms_polls: false,
            cms_social: false,
            core_menu_featured: false,
        },
        channels: [
            {
                key: "app",
                name: {
                    en: "Show on feed",
                    es: "Mostrar en el feed",
                    pt: "Mostrar no feed",
                }
            }
        ],
        feed: feedConfig,
        roles,
        cms: {
            boosters: {
                editor: {
                    flags: {
                        channels: true,
                    }
                },
            },
            staticPages: {
                editor: {
                    sections: STATIC_PAGES_CONFIG.sections,
                }
            },
            news: {
                editor: {
                    flags: {
                        channels: true,
                    }
                }
            },
        },
        blocksResolver: CUSTOM_BLOCKS.resolver,
        blockTypes: CUSTOM_BLOCKS.types,
    } as Partial<Config> & FlagsConfig & FeedConfig & CMSConfig,
    extensions: {
        dlo: DloExtension
    },
    configLoader: (config: Config) => {
        // Remove all cms.pages from base
        const modulesWithCmsPages = config.modules.filter(module => module.blocks && module.blocks["cms.pages"] && module.key !== "footlab")
        modulesWithCmsPages.forEach(module => {
            module.blocks!["cms.pages"] = []
            module.blocks!["cms.news"] = []
        })

        return config
    }
});

import { Module } from '@7egend/dxp-web-core/lib/bootstrap';
import loadable from "@loadable/component";

export const servicesSetup: Module = {
    routes: [
        // Services List
        {
            component: loadable(async () => (await import('./Services')).Services),
            path: "footlab/services",
            exact: true,
        },
        // Services Create
        {
            component: loadable(async () => (await import('./ServicesEditor')).ServicesEditor),
            path: "/footlab/services/add",
        },
        // Services Update
        {
            component: loadable(async () => (await import('./ServicesEditor')).ServicesEditor),
            path: "/footlab/services/edit/:id",
        },
    ],
    menus: [
        {
            title: {
                key: "footlab.services.menu.title",
                en: "Services",
                es: "Servicios",
                pt: "Serviços",
            },
            path: "footlab/services",
            permission: "footlab.services.list",
            children: [
                {
                    title: {
                        key: "footlab.services.edit.menu.title",
                        en: "Edit service",
                        es: "Editar servicio",
                        pt: "Editar serviço",
                    },
                    path: "footlab/services/edit",
                    visible: false,
                    permission: "footlab.services.edit",
                },
            ],
        },
    ]
}

import { StaticPageConfig } from '@7egend/dxp-web-core/lib/modules/cms/StaticPages/types'
import { CUSTOM_BLOCKS } from '../blocks'

const {
  cta,
  image,
  text,
  title,
  video,
} = CUSTOM_BLOCKS.types

export default {
  sections: {
    "16": {
      image: "",
      containers: {
        "booking/header": {
          blocks: [
            {
              types: [`${image.type}_${image.style}`]
            },
            {
              types: [`${title.type}_${title.style}`]
            }
          ]
        },
        "booking/introduction": {
          blocks: [
            {
              types: [`${title.type}_${title.style}`]
            },
            {
              types: [`${text.type}_${text.style}`]
            },
            {
              types: [`${text.type}_${text.style}`]
            },
            {
              types: [`${cta.type}_${cta.style}`]
            }
          ]
        },
        "booking/app": {
          blocks: [
            {
              types: [`${title.type}_${title.style}`]
            },
            {
              types: [`${text.type}_${text.style}`]
            },
            {
              types: [`${video.type}_${video.style}`]
            }
          ]
        }
      }
    }
  }
} as StaticPageConfig

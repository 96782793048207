import { Module } from '@7egend/dxp-web-core/lib/bootstrap';
import loadable from "@loadable/component";

export const locationsSetup: Module = {
    routes: [
        // Locations List
        {
            component: loadable(async () => (await import('./Locations')).Locations),
            path: "footlab/locations",
            exact: true,
        },
        // Locations Update
        {
            component: loadable(async () => (await import('./LocationsEditor')).LocationsEditor),
            path: "/footlab/locations/edit/:id",
        },
    ],
    menus: [
        {
            title: {
                key: "footlab.locations.menu.title",
                en: "Locations",
                es: "Ubicaciones",
                pt: "Localizações",
            },
            path: "footlab/locations",
            permission: "footlab.locations.list",
            children: [
                {
                    title: {
                        key: "footlab.locations.edit.menu.title",
                        en: "Edit location",
                        es: "Editar ubicacione",
                        pt: "Editar localização",
                    },
                    path: "footlab/locations/edit",
                    visible: false,
                    permission: "footlab.locations.edit",
                },
            ],
        },
    ]
}

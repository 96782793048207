/*eslint-disable no-template-curly-in-string */
import {
    DloInput,
    DloOutput,
    buildQLQuery,
    flattenComplexValues,
    QueryQLParams,
} from "@7egend/web-core/lib/extensions/dlo";
import { Medium } from "@7egend/web-core-media/lib/dlos/medium";
import { MediaListData } from "@7egend/dxp-web-core";

export interface PlaceServices {
    service: {
        id: string
        title: string
    }
}

export interface Locations {
    name: string,
    description: string,
    title: string;
    cover: Medium;
    header: Medium;
    album: MediaListData;
    address: {
        line1: string;
        line2: string;
        postal_code: string;
        city: string;
        country: {
            name: string;
            id: string;
        },
        latitude: string;
        longitude: string;
        email: string;
        cellphone: string;
        phone: string;
    }
    map_image: Medium;
    map_caption: string;
    place_services: PlaceServices[],
    data: [{
        id: string;
        title: string;
        description: string;
        type: string;
    }]
}

/**
 * # Locations Get all
 */
export class LocationsGetAllInput extends DloInput<{}> {
    public timeStamp = Date.now();
    public authentication = true;
    public uri = "geo-places/datatables?draw=${timeStamp}&type=1";
}

const generateServices = (services: PlaceServices[]) => {
    const generatedServices: string[] = [];
    services.forEach((eachService) => {
        generatedServices.push(eachService.service.id);
    });

    return generatedServices;
}

const LOCATION_FIELDS: QueryQLParams = [
    "name",
    "title",
    "description",
    "available",
    "uuid",
    ["cover",
        ["uuid", "image", "thumb", "medium_type"]
    ],
    ["header",
        ["uuid", "image", "thumb", "medium_type"]
    ],
    ["album",
        ["id", "uuid", "title", "medium_type",
            ["media",
                ["uuid", "image", "thumb", "medium_type"]
            ],
            ["cover",
                ["uuid", "image", "thumb", "medium_type"]
            ]
        ]
    ],
    ["address",
        ["line1", "line2", "postal_code", "latitude", "longitude", "city", "phone", "cellphone", "email",
            ["country",
                ["id", "name"]
            ]
        ]
    ],
    ["map_image",
        ["uuid", "image", "thumb", "medium_type"]
    ],
    "map_caption",
    ["place_services",
        [["service",
            ["id", "title"]
        ]]
    ],
    ["data",
        ["id", "title", "description", "type"]
    ]
];

export class LocationsUpdateInput extends DloInput<
    Locations & {
        uuid: string;
    }
    > {
    public method = "PUT";
    public uri = "footlab/locations/${id}";
    public fields = buildQLQuery(LOCATION_FIELDS);
    public transform = (input: any) => {
        flattenComplexValues(input.body, "cover", "uuid");
        flattenComplexValues(input.body, "header", "uuid");
        flattenComplexValues(input.body, "map_image", "uuid");
        flattenComplexValues(input.body, "album", "uuid");
        input.body.place_services = generateServices(input.body.place_services);
        input.body.fields = input.fields;
        return input;
    };
}

export class LocationsGetByIdInput extends DloInput<{
    id: number | string;
    locale: string;
}> {
    public uri = "footlab/locations/${id}?fields=${fields}&data_type=1,2";
    public fields = buildQLQuery(LOCATION_FIELDS);
}

export class LocationsFootlabGetAllInput extends DloInput<{
}> {
    public uri = "footlab/locations?fields=${fields}&data_type=1,2";
    public fields = buildQLQuery(LOCATION_FIELDS);
}

/** Locations Create <Output> */
export class LocationsCreateOutput extends DloOutput<Locations> { }

/**
 * # Countries Get all
 */
export class CountriesGetAllInput extends DloInput<{}> {
    public timeStamp = Date.now();
    public uri = "countries?draw=${timeStamp}&fields=id,name";
    public authentication = true;
}
